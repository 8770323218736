import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import InfoIllustrative from "../../../components/IllustrativeComponents/InfoIllustrative";
import ErrorIllustrative from "../../../components/IllustrativeComponents/ErrorIllustrative";
import { FlexColumn, FlexRow } from "../../../components/sharedComponents";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import EmptyIllustration from "../../../assets/EmptyIllustration.svg";
import ColoredTable from "../../../components/Table/ColoredTable";
import columns from "./columns";
import winnerColumns from "./winnerColumns";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getRewards,
  getWinners,
  updateRewardsFilter,
  updateWinnersFilter,
} from "../../../redux/features/rewardsSlice";
import { getUsers } from "../../../redux/features/usersSlice";
import Loader from "../../../components/Loader";
import RewardDialog from "./RewardDialog";
import ConfirmationDialog from "../../../components/Dialogs/ConfirmationDialog";
import AddButton from "../../../components/Buttons/AddButton";
import { 
  IconButton, 
  InputAdornment, 
  TextField, 
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import { prepareTranslation } from "../../../plugins/helper";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import _ from "underscore";
import { useTheme } from "@emotion/react";

const AdminRewards = () => {
  const { 
    rewardsData, 
    rewardsLoading, 
    rewardsError, 
    rewardsFilter, 
    rewardsTotal,
    winnersData,
    winnersLoading,
    winnersError,
    winnersFilter,
    winnersTotal
  } = useSelector((store) => store.rewards);
  const { usersData } = useSelector((store) => store.users);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [rewardDialogOpen, setRewardDialogOpen] = useState(false);
  const [currentReward, setCurrentReward] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [searchValue, setSearchValue] = useState(rewardsFilter?.term || "");
  const [showWinners, setShowWinners] = useState(false);
  const [activeFilters, setActiveFilters] = useState({
    isActive: undefined,
    targetUserId: "",
    isClaimed: undefined,
    isAnonymousWin: undefined
  });
  const theme = useTheme();

  useEffect(() => {
    // Always get users for both views
    dispatch(getUsers({ pageNumber: 1, pageSize: 1000 }));
    
    // Get rewards data if showing rewards or if we're showing winners (to have reward details)
    if (!showWinners || rewardsData.length === 0) {
      dispatch(getRewards(rewardsFilter));
    }
    
    // Get winners data if showing winners
    if (showWinners) {
      dispatch(getWinners(winnersFilter));
    }
  }, [rewardsFilter, winnersFilter, showWinners, dispatch, rewardsData.length]);

  const onRewardRowClick = (row) => {
    setCurrentReward(row);
    setRewardDialogOpen(true);
  };

  const handlePageChange = (event, newPage) => {
    if (showWinners) {
      dispatch(updateWinnersFilter({ ...winnersFilter, pageNumber: ++newPage }));
    } else {
      dispatch(updateRewardsFilter({ ...rewardsFilter, pageNumber: ++newPage }));
    }
  };

  const handleRowsPerPageChange = (event) => {
    if (showWinners) {
      dispatch(updateWinnersFilter({ ...winnersFilter, pageSize: event.target.value }));
    } else {
      dispatch(updateRewardsFilter({ ...rewardsFilter, pageSize: event.target.value }));
    }
  };

  const debouncedFilterSearch = useRef(
    _.debounce((filterValue, filter, isWinners) => {
      let newFilter = {
        pageNumber: 1,
        pageSize: filter.pageSize,
      };
      if (filterValue?.length > 0) newFilter["term"] = filterValue;
      if (isWinners) {
        dispatch(updateWinnersFilter(newFilter));
      } else {
        dispatch(updateRewardsFilter(newFilter));
      }
    }, 500)
  ).current;

  return (
    <>
      <ConfirmationDialog
        confirmDialogOpen={confirmDialogOpen}
        setConfirmDialogOpen={setConfirmDialogOpen}
        message={confirmMessage}
        confirmAction={confirmAction}
        includeLoader
      />
      <FlexColumn style={{ height: "100%", alignItems: "center" }}>
        <RewardDialog
          setConfirmDialogOpen={setConfirmDialogOpen}
          setConfirmMessage={setConfirmMessage}
          setConfirmAction={setConfirmAction}
          open={rewardDialogOpen}
          setOpen={setRewardDialogOpen}
          currentReward={currentReward}
          setCurrentReward={setCurrentReward}
        />
        <FlexRow
          style={{
            flex: 0,
            width: "100%",
            justifyContent: "space-between",
            boxSizing: "border-box",
            height: "85px",
            padding: "20px 7px 0 7px",
          }}
        >
          <Box
            component="img"
            alt="Illustration"
            src={EmptyIllustration}
            sx={{
              width: "130px",
              height: "130px",
              marginTop: "-30px",
              objectFit: "contain",
            }}
          />
          <Box sx={{ flex: 0.8, display: 'flex', gap: 2, alignItems: 'center' }}>
            <TextField
              sx={{ flex: 1 }}
              value={searchValue}
              variant="standard"
              onChange={(e) => {
                setSearchValue(e.target.value);
                debouncedFilterSearch(
                  e.target.value, 
                  showWinners ? winnersFilter : rewardsFilter,
                  showWinners
                );
              }}
              label={t(prepareTranslation(showWinners ? "search_winners" : "search_rewards"))}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
            
            <FormControl variant="standard" sx={{ minWidth: 120 }}>
              <InputLabel>{t(prepareTranslation("status"))}</InputLabel>
              <Select
                value={activeFilters.isActive === undefined ? "" : activeFilters.isActive}
                onChange={(e) => {
                  const value = e.target.value === "" ? undefined : e.target.value;
                  setActiveFilters({ ...activeFilters, isActive: value });
                  dispatch(updateRewardsFilter({ 
                    ...rewardsFilter, 
                    isActive: value,
                    pageNumber: 1 
                  }));
                }}
              >
                <MenuItem value="">
                  <em>{t(prepareTranslation("all"))}</em>
                </MenuItem>
                <MenuItem value={true}>{t(prepareTranslation("active"))}</MenuItem>
                <MenuItem value={false}>{t(prepareTranslation("inactive"))}</MenuItem>
              </Select>
            </FormControl>

            {showWinners && (
              <>
                <FormControl variant="standard" sx={{ minWidth: 120 }}>
                  <InputLabel>{t(prepareTranslation("claimed"))}</InputLabel>
                  <Select
                    value={activeFilters.isClaimed === undefined ? "" : activeFilters.isClaimed}
                    onChange={(e) => {
                      const value = e.target.value === "" ? undefined : e.target.value;
                      setActiveFilters({ ...activeFilters, isClaimed: value });
                      dispatch(updateWinnersFilter({
                        ...winnersFilter,
                        isClaimed: value,
                        pageNumber: 1
                      }));
                    }}
                  >
                    <MenuItem value="">
                      <em>{t(prepareTranslation("all"))}</em>
                    </MenuItem>
                    <MenuItem value={true}>{t(prepareTranslation("claimed"))}</MenuItem>
                    <MenuItem value={false}>{t(prepareTranslation("unclaimed"))}</MenuItem>
                  </Select>
                </FormControl>

                <FormControl variant="standard" sx={{ minWidth: 120 }}>
                  <InputLabel>{t(prepareTranslation("win_type"))}</InputLabel>
                  <Select
                    value={activeFilters.isAnonymousWin === undefined ? "" : activeFilters.isAnonymousWin}
                    onChange={(e) => {
                      const value = e.target.value === "" ? undefined : e.target.value;
                      setActiveFilters({ ...activeFilters, isAnonymousWin: value });
                      dispatch(updateWinnersFilter({
                        ...winnersFilter,
                        isAnonymousWin: value,
                        pageNumber: 1
                      }));
                    }}
                  >
                    <MenuItem value="">
                      <em>{t(prepareTranslation("all"))}</em>
                    </MenuItem>
                    <MenuItem value={true}>{t(prepareTranslation("anonymous"))}</MenuItem>
                    <MenuItem value={false}>{t(prepareTranslation("authenticated"))}</MenuItem>
                  </Select>
                </FormControl>
              </>
            )}
          </Box>

          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Tooltip title={t(prepareTranslation("toggle_winners_view"))}>
              <IconButton
                sx={{
                  color: showWinners ? theme.palette.primary.main : 'inherit',
                }}
                onClick={() => {
                  setShowWinners(!showWinners);
                  if (!showWinners) {
                    dispatch(getWinners(winnersFilter));
                  } else {
                    dispatch(getRewards(rewardsFilter));
                  }
                }}
              >
                <EmojiEventsOutlinedIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title={t(prepareTranslation("refresh_data"))}>
              <IconButton
                sx={{
                  width: "1.5em",
                  height: "1.5em",
                  color: theme.palette.primary.main,
                  marginTop: "0.5em",
                }}
                onClick={() => {
                  if (showWinners) {
                    dispatch(getWinners(winnersFilter));
                  } else {
                    dispatch(getRewards(rewardsFilter));
                  }
                }}
              >
                <CachedOutlinedIcon sx={{ width: "1.3em", height: "1.3em" }} />
              </IconButton>
            </Tooltip>
          </Box>

          {!showWinners && (
            <AddButton
              buttonstyle={{
                marginTop: "7px",
              }}
              buttonText="Add reward"
              onClick={() => setRewardDialogOpen(true)}
            />
          )}
        </FlexRow>
        {showWinners ? (
          winnersLoading ? (
            <Loader loaderSize="6em" />
          ) : winnersError?.length > 0 ? (
            <ErrorIllustrative error={winnersError} />
          ) : winnersData?.length > 0 ? (
            <ColoredTable
              tablestyle={{
                width: "100%",
                marginTop: "30px",
                maxHeight: `calc(100% - 85px - 30px - 30px - 20px)`,
                overflowY: "scroll",
              }}
              rows={winnersData}
              columns={winnerColumns(t, { usersData, rewardsData })}
              headercolor="#831c1a"
              oddrowcolor="#831c1a12"
              hoverrowcolor="#831c1a26"
              firstcolicon={EmojiEventsOutlinedIcon}
              page={winnersFilter.pageNumber - 1}
              onRowsPerPageChange={handleRowsPerPageChange}
              onPageChange={handlePageChange}
              totalCount={winnersTotal}
              rowsPerPage={winnersFilter.pageSize}
              additionalData={{ usersData, rewardsData }}
            />
          ) : (
            <FlexRow
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-3em",
                width: "100%",
              }}
            >
              <InfoIllustrative header={t("no_winners_yet")} imageSize="150px" />
            </FlexRow>
          )
        ) : (
          rewardsLoading ? (
            <Loader loaderSize="6em" />
          ) : rewardsError?.length > 0 ? (
            <ErrorIllustrative error={rewardsError} />
          ) : rewardsData?.length > 0 ? (
            <ColoredTable
              tablestyle={{
                width: "100%",
                marginTop: "30px",
                maxHeight: `calc(100% - 85px - 30px - 30px - 20px)`,
                overflowY: "scroll",
              }}
              rows={rewardsData}
              columns={columns(t, { usersData })}
              headercolor="#831c1a"
              oddrowcolor="#831c1a12"
              hoverrowcolor="#831c1a26"
              firstcolicon={CardGiftcardOutlinedIcon}
              onRowClick={(row) => onRewardRowClick(row)}
              page={rewardsFilter.pageNumber - 1}
              onRowsPerPageChange={handleRowsPerPageChange}
              onPageChange={handlePageChange}
              totalCount={rewardsTotal}
              rowsPerPage={rewardsFilter.pageSize}
              additionalData={{ usersData }}
            />
          ) : (
            <FlexRow
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-3em",
                width: "100%",
              }}
            >
              <InfoIllustrative header={t("no_results")} imageSize="150px" />
            </FlexRow>
          )
        )}
      </FlexColumn>
    </>
  );
};

export default AdminRewards;
