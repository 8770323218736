import React from 'react';
import { Box, Tooltip, AvatarGroup, Avatar, Chip } from '@mui/material';
import { prepareTranslation } from '../../../plugins/helper';

const columns = (t, additionalData) => {
  const usersData = additionalData?.usersData || [];

  return [
    {
      id: 'title',
      field: 'title',
      label: 'title',
      flex: 1,
      render: (value, row) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box
            component="img"
            src={`https://tagtec.ch/Resources/Images/${row.imageUrl}`}
            alt=""
            sx={{
              width: 32,
              height: 32,
              borderRadius: '4px',
              backgroundColor: row.backgroundColor,
              objectFit: 'cover'
            }}
          />
          <span>{row.title}</span>
        </Box>
      )
    },
    {
      id: 'description',
      field: 'description',
      label: 'description',
      flex: 2
    },
    {
      id: 'targetUsers',
      field: 'targetUsers',
      label: 'target_users',
      flex: 1.5,
      render: (value, row) => (
        <AvatarGroup max={3}>
          {row.targetUserId && (
            <Tooltip 
              key={row.targetUserId}
              title={usersData?.find(u => u.id === row.targetUserId)?.userName || row.targetUserId}
            >
              <Avatar>
                {(usersData?.find(u => u.id === row.targetUserId)?.userName || row.targetUserId).substring(0, 2).toUpperCase()}
              </Avatar>
            </Tooltip>
          )}
        </AvatarGroup>
      )
    },
    {
      id: 'frequency',
      field: 'frequency',
      label: 'frequency',
      flex: 1,
      render: (value, row) => (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
          <span>{row.frequency}</span>
          {row.resetDaily && (
            <Tooltip title={t(prepareTranslation('resets_daily'))}>
              <Chip 
                label={t(prepareTranslation('daily'))} 
                size="small" 
                color="primary" 
                variant="outlined"
              />
            </Tooltip>
          )}
        </Box>
      )
    },
    {
      id: 'expiryDate',
      field: 'expiryDate',
      label: 'expiry_date',
      flex: 1,
      render: (value, row) => {
        const date = new Date(row.expiryDate);
        const isExpired = date < new Date();
        return (
          <Chip
            label={date.toLocaleDateString()}
            size="small"
            color={isExpired ? 'error' : 'default'}
            variant={isExpired ? 'filled' : 'outlined'}
          />
        );
      }
    },
    {
      id: 'isActive',
      field: 'isActive',
      label: 'status',
      flex: 0.8,
      render: (value, row) => (
        <Chip
          label={row.isActive ? t(prepareTranslation('active')) : t(prepareTranslation('inactive'))}
          color={row.isActive ? 'success' : 'default'}
          size="small"
        />
      )
    }
  ];
};

export default columns;
