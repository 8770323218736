import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import { ChromePicker } from 'react-color';
import ImageUpload from "../../../components/ImageUpload";
import { RewardFormData } from "../../../types/rewards.js";
import { useTranslation } from "react-i18next";
import { prepareTranslation } from "../../../plugins/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  createReward,
  deleteReward,
  updateReward,
} from "../../../redux/features/rewardsSlice";
import SaveButton from "../../../components/Buttons/SaveButton";
import DeleteButton from "../../../components/Buttons/DeleteButton";

const RewardDialog = ({
  open,
  setOpen,
  currentReward,
  setCurrentReward,
  setConfirmDialogOpen,
  setConfirmMessage,
  setConfirmAction,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { usersData } = useSelector((store) => store.users);

  const [formData, setFormData] = useState({
    description: "",
    title: "",
    backgroundColor: "#FFFFFF",
    image: null,
    removeExistingImage: false,
    frequency: "",
    resetDaily: false,
    expiryDate: null,
    targetUserIds: [],
    isActive: true,
  });

  const [showColorPicker, setShowColorPicker] = useState(false);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (currentReward) {
      setFormData({
        description: currentReward.description || "",
        title: currentReward.title || "",
        backgroundColor: currentReward.backgroundColor || "#FFFFFF",
        image: null,
        removeExistingImage: false,
        frequency: currentReward.frequency || "",
        resetDaily: currentReward.resetDaily || false,
        expiryDate: currentReward.expiryDate ? new Date(currentReward.expiryDate) : null,
        targetUserIds: currentReward.targetUserId ? [currentReward.targetUserId] : [],
        isActive: currentReward.isActive ?? true,
      });
    }
  }, [currentReward]);

  const validateForm = () => {
    const newErrors = {};

    if (!formData.title.trim()) {
      newErrors.title = t(prepareTranslation("Title is required"));
    }

    if (!formData.description.trim()) {
      newErrors.description = t(prepareTranslation("Description is required"));
    }

    if (!formData.frequency || formData.frequency <= 0) {
      newErrors.frequency = t(prepareTranslation("Frequency must be a positive number"));
    }

    if (!formData.expiryDate) {
      newErrors.expiryDate = t(prepareTranslation("Expiry date is required"));
    } else if (formData.expiryDate < new Date()) {
      newErrors.expiryDate = t(prepareTranslation("Expiry date must be in the future"));
    }

    if (!formData.targetUserIds.length) {
      newErrors.targetUserIds = t(prepareTranslation("At least one target user is required"));
    }

    if (!formData.frequency || isNaN(formData.frequency) || formData.frequency < 1) {
      newErrors.frequency = t(prepareTranslation("Frequency must be a positive number"));
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const rewardData = {
      ...formData,
      expiryDate: formData.expiryDate?.toISOString(),
    };

    const result = currentReward
      ? await dispatch(updateReward(currentReward.id, rewardData))
      : await dispatch(createReward(rewardData));

    if (result.success) {
      handleClose();
    }
  };

  const handleDelete = () => {
    setConfirmMessage(
      t(prepareTranslation("Are you sure you want to delete this reward?"))
    );
    setConfirmAction(async () => {
      const result = await dispatch(deleteReward(currentReward.id));
      if (result.success) {
        handleClose();
      }
      return result;
    });
    setConfirmDialogOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentReward(null);
    setFormData({
      description: "",
      title: "",
      backgroundColor: "#FFFFFF",
      image: null,
      removeExistingImage: false,
      frequency: "",
      resetDaily: false,
      expiryDate: null,
      targetUserIds: [],
      isActive: true,
    });
    setErrors({});
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: { borderRadius: "10px" },
      }}
    >
      <DialogTitle>
        {currentReward
          ? t(prepareTranslation("Edit reward"))
          : t(prepareTranslation("Add reward"))}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          {/* Row for Title, Frequency, Expiry Date, and Background Color */}
          <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
            <TextField
              label={t(prepareTranslation("Title"))}
              value={formData.title}
              onChange={(e) =>
                setFormData({ ...formData, title: e.target.value })
              }
              error={!!errors.title}
              helperText={errors.title}
              sx={{ flex: 2 }}
            />
            <TextField
              label={t(prepareTranslation("Frequency"))}
              type="number"
              value={formData.frequency}
              onChange={(e) =>
                setFormData({ ...formData, frequency: parseInt(e.target.value) || "" })
              }
              error={!!errors.frequency}
              helperText={errors.frequency}
              sx={{ flex: 1 }}
            />
            <TextField
              type="date"
              label={t(prepareTranslation("Expiry Date"))}
              value={formData.expiryDate ? new Date(formData.expiryDate).toISOString().split('T')[0] : ''}
              onChange={(e) =>
                setFormData({ ...formData, expiryDate: new Date(e.target.value) })
              }
              error={!!errors.expiryDate}
              helperText={errors.expiryDate}
              sx={{ flex: 1.5 }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: new Date().toISOString().split('T')[0]
              }}
            />
            <TextField
              label={t(prepareTranslation("Background Color"))}
              value={formData.backgroundColor}
              onClick={() => setShowColorPicker(true)}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      sx={{
                        width: 20,
                        height: 20,
                        borderRadius: 1,
                        bgcolor: formData.backgroundColor,
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{ flex: 1 }}
            />
          </Box>
          <TextField
            fullWidth
            label={t(prepareTranslation("Description"))}
            value={formData.description}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
            error={!!errors.description}
            helperText={errors.description}
            sx={{ mb: 2 }}
          />
          {showColorPicker && (
            <Box sx={{ position: 'absolute', zIndex: 2 }}>
              <Box
                sx={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0 }}
                onClick={() => setShowColorPicker(false)}
              />
              <ChromePicker
                color={formData.backgroundColor}
                onChange={(color) => setFormData({ ...formData, backgroundColor: color.hex })}
              />
            </Box>
          )}

          <ImageUpload
            currentImage={currentReward?.imageUrl ? `https://tagtec.ch/Resources/Images/${currentReward.imageUrl}` : null}
            onImageChange={(file) => setFormData({ ...formData, image: file, removeExistingImage: false })}
            onRemoveImage={() => setFormData({ ...formData, image: null, removeExistingImage: true })}
          />

          <Autocomplete
            multiple
            options={usersData || []}
            getOptionLabel={(option) => option.userName || ""}
            value={(usersData || []).filter(user => formData.targetUserIds.includes(user.id))}
            onChange={(event, newValue) =>
              setFormData({ 
                ...formData, 
                targetUserIds: newValue.map(user => user.id)
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t(prepareTranslation("Target Users"))}
                fullWidth
                error={!!errors.targetUserIds}
                helperText={errors.targetUserIds}
                sx={{ mb: 2 }}
              />
            )}
          />
          {/* Row for Reset Daily and Active checkboxes */}
          <Box sx={{ display: "flex", gap: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.resetDaily}
                  onChange={(e) =>
                    setFormData({ ...formData, resetDaily: e.target.checked })
                  }
                />
              }
              label={t(prepareTranslation("Reset Daily"))}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.isActive}
                  onChange={(e) =>
                    setFormData({ ...formData, isActive: e.target.checked })
                  }
                />
              }
              label={t(prepareTranslation("Active"))}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "20px" }}>
        {currentReward && (
          <DeleteButton
            onClick={handleDelete}
            buttonstyle={{ marginRight: "auto" }}
            buttonText="Delete"
          />
        )}
        <Button onClick={handleClose}>{t(prepareTranslation("Cancel"))}</Button>
        <SaveButton onClick={handleSubmit} />
      </DialogActions>
    </Dialog>
  );
};

export default RewardDialog;
