import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  addConnection,
  deleteConnection,
  getProfileByUserId,
  incrementVisitsNumber,
} from "../../api/apis";
import ProfileWithSocialMedia from "../../components/ProfileWithSocialMedia";
import AssignmentReturnOutlinedIcon from "@mui/icons-material/AssignmentReturnOutlined";
import { Box, Button } from "@mui/material";
import {
  checkUserRole,
  errorParser,
  isLoggedIn,
  prepareTranslation,
  BASIC_ROLE,
  ADMIN_ROLE,
} from "../../plugins/helper";
import { useTranslation } from "react-i18next";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { actionButtonStyle, buttonIconStyle } from "./style";
import { useDispatch } from "react-redux";
import { invokeSnackMessage } from "../../redux/features/coreSlice";
import ConfirmationDialog from "../../components/Dialogs/ConfirmationDialog";
import ErrorIllustrative from "../../components/IllustrativeComponents/ErrorIllustrative";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import RewardModal from "../../components/RewardModal";
import { getOrCreateDeviceId } from "../../utils/deviceId";
import {
  checkReward,
  claimReward,
  registerAnonymous,
  generateRedemptionCode
} from "../../redux/features/rewardsSlice";

const TagPage = () => {
  const theme = useTheme();
  const [profile, setProfile] = useState(null);
  const { userId } = useParams();
  const [error, setError] = useState("");
  const { me } = useSelector((store) => store.auth);
  const { ownProfile } = useSelector((store) => store.core);
  const [rewardModalOpen, setRewardModalOpen] = useState(false);
  const [wonReward, setWonReward] = useState(null);
  const [isClaimable, setIsClaimable] = useState(true);
  const [isAnonymousUser, setIsAnonymousUser] = useState(false);
  const [redemptionCode, setRedemptionCode] = useState('');

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Check for rewards when profile is loaded
  const checkForReward = async (profileId) => {
    const deviceId = getOrCreateDeviceId();
    const currentUserId = me?.id;
    
    try {
      const result = await dispatch(checkReward(profileId, deviceId, currentUserId));
      console.log("Reward check result:", result);
      
      // Handle different response structures
      const data =
        (result?.payload?.data) ||  // Redux toolkit standard
        (result?.data);             // Direct return from action
      
      if (data) {
        console.log("Setting reward data:", data);
        setWonReward(data);
        setRewardModalOpen(true);
        setIsClaimable(!data.isClaimed);
        
        // Check if this is an anonymous win (no user ID)
        setIsAnonymousUser(!currentUserId || data.isAnonymousWin);
      }
    } catch (error) {
      console.error("Error checking for reward:", error);
    }
  };

  // Handle registering an email for anonymous users
  const handleRegisterEmail = async (email) => {
    if (!wonReward || !email) return;
    
    try {
      const result = await dispatch(registerAnonymous(wonReward.id, email));
      console.log("Email registration result:", result);
      
      // The API returns HTTP 200 with payload containing rewardWinnerId and pendingEmail
      // We need to check if there's a payload at all, not for a specific success property
      if (result.payload) {
        // Consider any non-error response as success
        console.log("Email registered successfully:", result.payload);
        // Return true to indicate success
        return true;
      } else if (result.error) {
        // Only show error if there's an explicit error property
        dispatch(
          invokeSnackMessage({
            message: result.error || "Failed to register email",
            isError: true,
          })
        );
        return false;
      }
      
      // Return true by default for HTTP 200 responses
      return true;
    } catch (error) {
      console.error("Error registering email:", error);
      dispatch(
        invokeSnackMessage({
          message: "Error registering email",
          isError: true,
        })
      );
      throw error;
    }
  };

  // Handle generating a redemption code for anonymous users
  const handleGenerateCode = async () => {
    if (!wonReward) return;
    
    try {
      const result = await dispatch(generateRedemptionCode(wonReward.id));
      console.log("Code generation result:", result);
      
      // Handle different response structures
      // The generateRedemptionCode action might return the data directly or via payload
      const data = result.payload || result;
      
      if (data.success) {
        const code = data.code;
        setRedemptionCode(code);
        return code;
      } else {
        dispatch(
          invokeSnackMessage({
            message: data.error || "Failed to generate code",
            isError: true,
          })
        );
      }
    } catch (error) {
      console.error("Error generating code:", error);
      dispatch(
        invokeSnackMessage({
          message: "Error generating redemption code",
          isError: true,
        })
      );
      throw error;
    }
  };

  // Handle claiming a reward
  const handleClaimReward = async () => {
    if (!wonReward) return;
    
    const currentUserId = me?.id;
    
    // If user is not logged in, we'll handle through the anonymous flow in the modal
    if (!currentUserId) {
      return;
    }
    
    try {
      const result = await dispatch(claimReward(wonReward.id, currentUserId));
      
      if (result.payload && result.payload.success) {
        setWonReward(result.payload.data);
        setIsClaimable(false);
        dispatch(
          invokeSnackMessage({
            message: "Reward claimed successfully!",
            isError: false,
          })
        );
      } else {
        dispatch(
          invokeSnackMessage({
            message: result.payload?.error || "Failed to claim reward",
            isError: true,
          })
        );
      }
    } catch (error) {
      console.error("Error claiming reward:", error);
      dispatch(
        invokeSnackMessage({
          message: "Error claiming reward",
          isError: true,
        })
      );
    }
  };

  useEffect(() => {
    if (userId != null) {
      getProfileByUserId(userId)
        .then((res) => {
          setProfile(res.data);
          if (checkUserRole(me?.role, BASIC_ROLE) && ownProfile != null)
            incrementVisitsNumber(res.data.id);
          
          // Check for rewards after loading profile
          checkForReward(res.data.id);
        })
        .catch((err) => {
          setError(errorParser(err));
        });
    }
  }, [userId, me, ownProfile]);

  // Check for pending reward claims (after login)
  useEffect(() => {
    const pendingRewardClaim = sessionStorage.getItem('pendingRewardClaim');
    
    if (pendingRewardClaim && me?.id) {
      try {
        const { rewardWinnerId } = JSON.parse(pendingRewardClaim);
        
        if (rewardWinnerId) {
          dispatch(claimReward(rewardWinnerId, me.id))
            .then(result => {
              if (result.payload && result.payload.success) {
                dispatch(
                  invokeSnackMessage({
                    message: "Reward claimed successfully!",
                    isError: false,
                  })
                );
              }
            });
        }
      } catch (e) {
        console.error("Error processing pending reward claim:", e);
      }
      
      // Clear pending claim
      sessionStorage.removeItem('pendingRewardClaim');
    }
  }, [me, dispatch]);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState("");

  const confirmDialogEndActions = () => {
    setConfirmDialogOpen(false);
    getProfileByUserId(userId).then((res) => {
      setProfile(res.data);
    });
  };

  const connectionDelete = () => {
    deleteConnection(userId)
      .then((res) => {
        dispatch(
          invokeSnackMessage({
            message: "Successfully deleted connection",
            isError: false,
          })
        );
      })
      .finally(() => {
        confirmDialogEndActions();
      });
  };

  const connectionAdd = () => {
    addConnection(userId)
      .then((res) => {
        dispatch(
          invokeSnackMessage({
            message: "Successfully added connection",
            isError: false,
          })
        );
      })
      .finally(() => {
        confirmDialogEndActions();
      });
  };

  return error?.length > 0 ? (
    <ErrorIllustrative error={error} containerProps={{ height: "70%" }} />
  ) : profile != null ? (
    <>
      <ConfirmationDialog
        confirmDialogOpen={confirmDialogOpen}
        setConfirmDialogOpen={setConfirmDialogOpen}
        confirmAction={confirmAction}
        message={confirmMessage}
      />
      <RewardModal
        open={rewardModalOpen}
        onClose={() => setRewardModalOpen(false)}
        reward={wonReward}
        onClaim={handleClaimReward}
        isClaimable={isClaimable}
        isAnonymousUser={isAnonymousUser}
        onRegisterEmail={handleRegisterEmail}
        onGenerateCode={handleGenerateCode}
      />
      {!(isLoggedIn() && checkUserRole(me?.role, BASIC_ROLE)) ? (
        <Button
          onClick={() =>
            navigate(
              !isLoggedIn()
                ? `/login?redirectusertag=${userId}`
                : checkUserRole(me?.role, ADMIN_ROLE)
                ? "/adminpanel"
                : "/moderatorpanel"
            )
          }
          sx={actionButtonStyle}
          variant="outlined"
        >
          <AssignmentReturnOutlinedIcon sx={buttonIconStyle} />
          {t(
            prepareTranslation(
              !isLoggedIn()
                ? "Login"
                : checkUserRole(me?.role, ADMIN_ROLE)
                ? "A. panel"
                : "M. panel"
            )
          )}
        </Button>
      ) : ownProfile == null ? (
        <Box
          sx={{ position: "relative", left: 0, top: "20px", height: "35px" }}
        />
      ) : profile?.areConnected ? (
        <Button
          onClick={() => {
            setConfirmAction(() => () => {
              connectionDelete();
            });
            setConfirmMessage("Delete connection");
            setConfirmDialogOpen(true);
          }}
          sx={{
            ...actionButtonStyle,
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            "&:hover": {
              borderColor: theme.palette.primary.main,
              background: "#c704040f",
            },
          }}
          variant="outlined"
        >
          <FavoriteBorderOutlinedIcon sx={buttonIconStyle} />
          {t(prepareTranslation("Delete connection"))}
        </Button>
      ) : (
        <Button
          onClick={() => {
            setConfirmAction(() => () => {
              connectionAdd();
            });
            setConfirmMessage("Add to connections");
            setConfirmDialogOpen(true);
          }}
          sx={actionButtonStyle}
          variant="outlined"
        >
          <FavoriteBorderOutlinedIcon sx={buttonIconStyle} />
          {t(prepareTranslation("Add to connections"))}
        </Button>
      )}
      <ProfileWithSocialMedia
        profile={profile}
        containerMarginTop="-50px"
        banners={{
          bannersUri: profile?.bannersUri,
          bannerAdvertisementLinks: profile?.bannerAdvertisementLinks
        }}
      />
    </>
  ) : null;
};

export default TagPage;
