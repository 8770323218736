import React from "react";
import InfoIllustrative from "../../components/IllustrativeComponents/InfoIllustrative";
import { Box, Typography, Paper } from "@mui/material";
import { FlexColumn } from "../../components/sharedComponents";
import { useTranslation } from "react-i18next";
import { prepareTranslation } from "../../plugins/helper";

const AccountDeletion = () => {
  const { t } = useTranslation();

  return (
    <FlexColumn style={{ minHeight: "100vh", padding: "2em 1em" }}>
      <Paper elevation={0} sx={{ maxWidth: 800, margin: "auto", padding: "2em", width: "100%" }}>
        <InfoIllustrative
          imageSize="150px"
          header="Account Deletion"
          text="Below you will find information on how to delete your account"
        />
        
        <Box sx={{ margin: "2em auto", maxWidth: 600 }}>
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
            {t(prepareTranslation("How to Delete Your Account"))}
          </Typography>
          
          <Typography variant="body1" sx={{ mb: 3 }}>
            {t(prepareTranslation("To delete your account and all associated data, please follow these steps:"))}
          </Typography>
          
          <Box sx={{ ml: 2, mb: 4 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>1.</strong> {t(prepareTranslation("Log in to your account"))}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>2.</strong> {t(prepareTranslation("Navigate to your Profile page"))}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>3.</strong> {t(prepareTranslation("Click the \"Delete profile\" button"))}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>4.</strong> {t(prepareTranslation("Confirm deletion in the dialog that appears"))}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>5.</strong> {t(prepareTranslation("Your account will be immediately and permanently deleted"))}
            </Typography>
          </Box>
          
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
            {t(prepareTranslation("Data Deletion Policy"))}
          </Typography>
          
          <Typography variant="body1" sx={{ mb: 2 }}>
            {t(prepareTranslation("When you delete your account:"))}
          </Typography>
          
          <Box sx={{ ml: 2, mb: 4 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              • {t(prepareTranslation("All your personal information is permanently deleted"))}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              • {t(prepareTranslation("All your profile data, including photos and social networks, is permanently deleted"))}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              • {t(prepareTranslation("All your connections are permanently removed"))}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              • {t(prepareTranslation("We do not retain any of your data after account deletion"))}
            </Typography>
          </Box>
          
          <Typography variant="body1" sx={{ fontWeight: "bold", mb: 2 }}>
            {t(prepareTranslation("The deletion is immediate and cannot be undone. Once deleted, you cannot recover your account data."))}
          </Typography>
          
          <Typography variant="body2" sx={{ mt: 4, color: "text.secondary" }}>
            {t(prepareTranslation("If you're having trouble deleting your account or have any questions, please contact our support team."))}
          </Typography>
        </Box>
      </Paper>
    </FlexColumn>
  );
};

export default AccountDeletion;