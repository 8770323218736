import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/config";
import { Reward, RewardWinner } from "../../types/rewards.js";

const initialState = {
  rewardsData: [],
  rewardsLoading: false,
  rewardsError: "",
  rewardsTotal: 0,
  rewardsFilter: {
    pageNumber: 1,
    pageSize: 10,
    term: "",
    isActive: undefined,
    targetUserId: undefined
  },
  winnersData: [],
  winnersLoading: false,
  winnersError: "",
  winnersTotal: 0,
  winnersFilter: {
    pageNumber: 1,
    pageSize: 10,
    term: "",
    isClaimed: undefined,
    rewardId: undefined,
    isAnonymousWin: undefined
  },
};

const rewardsSlice = createSlice({
  name: "rewards",
  initialState,
  reducers: {
    setRewardsLoading: (state, action) => {
      state.rewardsLoading = action.payload;
      if (action.payload) state.rewardsError = "";
    },
    setRewardsError: (state, action) => {
      state.rewardsError = action.payload;
      state.rewardsLoading = false;
    },
    setRewardsData: (state, action) => {
      state.rewardsData = action.payload.list;
      state.rewardsTotal = action.payload.totalCount;
      state.rewardsLoading = false;
      state.rewardsError = "";
    },
    updateRewardsFilter: (state, action) => {
      state.rewardsFilter = action.payload;
    },
    setWinnersLoading: (state, action) => {
      state.winnersLoading = action.payload;
      if (action.payload) state.winnersError = "";
    },
    setWinnersError: (state, action) => {
      state.winnersError = action.payload;
      state.winnersLoading = false;
    },
    setWinnersData: (state, action) => {
      state.winnersData = action.payload.list;
      state.winnersTotal = action.payload.totalCount;
      state.winnersLoading = false;
      state.winnersError = "";
    },
    updateWinnersFilter: (state, action) => {
      state.winnersFilter = action.payload;
    },
  },
});

export const {
  setRewardsLoading,
  setRewardsError,
  setRewardsData,
  updateRewardsFilter,
  setWinnersLoading,
  setWinnersError,
  setWinnersData,
  updateWinnersFilter,
} = rewardsSlice.actions;

const handleError = (error) => {
  if (error.response) {
    switch (error.response.status) {
      case 400:
        return "Validation failed";
      case 403:
        return "Permission denied";
      case 404:
        return "Reward not found";
      default:
        return error.response.data?.message || error.message;
    }
  }
  return error.message;
};

export const getRewards = (filter) => async (dispatch) => {
  try {
    dispatch(setRewardsLoading(true));
    const response = await axiosInstance.post("/Rewards/search", {
      pageNumber: filter.pageNumber,
      pageSize: filter.pageSize,
      term: filter.term,
      isActive: filter.isActive,
      targetUserId: filter.targetUserId
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    dispatch(setRewardsData(response.data));
  } catch (error) {
    const errorMessage = handleError(error);
    dispatch(setRewardsError(errorMessage));
  }
};

export const createReward = (rewardData) => async (dispatch, getState) => {
  try {
    dispatch(setRewardsLoading(true));
    const formData = new FormData();
    
    // Append basic fields
    formData.append('description', rewardData.description);
    formData.append('title', rewardData.title);
    formData.append('backgroundColor', rewardData.backgroundColor);
    formData.append('frequency', rewardData.frequency.toString());
    formData.append('resetDaily', rewardData.resetDaily.toString());
    formData.append('expiryDate', rewardData.expiryDate);
    formData.append('isActive', rewardData.isActive.toString());
    
    // Append image if exists
    if (rewardData.image) {
      formData.append('image', rewardData.image);
    }
    
    // Append target user IDs
    rewardData.targetUserIds.forEach(id => {
      formData.append('targetUserIds', id);
    });

    const response = await axiosInstance.post("/Rewards", formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    
    dispatch(getRewards(getState().rewards.rewardsFilter));
    return { success: true, id: response.data };
  } catch (error) {
    const errorMessage = handleError(error);
    dispatch(setRewardsError(errorMessage));
    return { success: false, error: errorMessage };
  }
};

export const updateReward = (id, rewardData) => async (dispatch, getState) => {
  try {
    dispatch(setRewardsLoading(true));
    const formData = new FormData();
    
    // Append required ID
    formData.append('id', id.toString());
    
    // Append basic fields
    formData.append('description', rewardData.description);
    formData.append('title', rewardData.title);
    formData.append('backgroundColor', rewardData.backgroundColor);
    formData.append('frequency', rewardData.frequency.toString());
    formData.append('resetDaily', rewardData.resetDaily.toString());
    formData.append('expiryDate', rewardData.expiryDate);
    formData.append('isActive', rewardData.isActive.toString());
    
    // Handle image
    if (rewardData.image) {
      formData.append('image', rewardData.image);
    }
    formData.append('removeExistingImage', rewardData.removeExistingImage.toString());
    
    // Append target user IDs
    rewardData.targetUserIds.forEach(id => {
      formData.append('targetUserIds', id);
    });

    await axiosInstance.put(`/Rewards/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    
    dispatch(getRewards(getState().rewards.rewardsFilter));
    return { success: true };
  } catch (error) {
    const errorMessage = handleError(error);
    dispatch(setRewardsError(errorMessage));
    return { success: false, error: errorMessage };
  }
};

export const deleteReward = (id) => async (dispatch, getState) => {
  try {
    dispatch(setRewardsLoading(true));
    await axiosInstance.delete(`/Rewards/${id}`);
    dispatch(getRewards(getState().rewards.rewardsFilter));
    return { success: true };
  } catch (error) {
    const errorMessage = handleError(error);
    dispatch(setRewardsError(errorMessage));
    return { success: false, error: errorMessage };
  }
};

export const getWinners = (filter) => async (dispatch) => {
  try {
    dispatch(setWinnersLoading(true));
    const response = await axiosInstance.post("/Rewards/winners/search", {
      pageNumber: filter.pageNumber,
      pageSize: filter.pageSize,
      term: filter.term,
      isClaimed: filter.isClaimed,
      rewardId: filter.rewardId,
      isAnonymousWin: filter.isAnonymousWin
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    dispatch(setWinnersData(response.data));
  } catch (error) {
    const errorMessage = handleError(error);
    dispatch(setWinnersError(errorMessage));
  }
};

export const checkReward = (profileId, deviceId, userId) => async (dispatch) => {
  try {
    console.log("Checking reward with profileId:", profileId);
    const response = await axiosInstance.post("/Rewards/check", {
      profileId,
      deviceId,
      userId
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    console.log("Reward check API response:", response.data);
    return { success: true, data: response.data };
  } catch (error) {
    console.error("Error in checkReward:", error);
    const errorMessage = handleError(error);
    return { success: false, error: errorMessage };
  }
};

export const claimReward = (rewardWinnerId, userId) => async () => {
  try {
    const response = await axiosInstance.post("/Rewards/claim", {
      rewardWinnerId,
      userId
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { success: true, data: response.data };
  } catch (error) {
    const errorMessage = handleError(error);
    return { success: false, error: errorMessage };
  }
};

export const registerAnonymous = (rewardWinnerId, pendingEmail) => async () => {
  try {
    const response = await axiosInstance.post("/Rewards/register-anonymous", {
      rewardWinnerId,
      pendingEmail
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { success: true, data: response.data };
  } catch (error) {
    const errorMessage = handleError(error);
    return { success: false, error: errorMessage };
  }
};

export const generateRedemptionCode = (rewardWinnerId) => async () => {
  try {
    const response = await axiosInstance.post("/Rewards/generate-code", {
      rewardWinnerId
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { success: true, code: response.data };
  } catch (error) {
    const errorMessage = handleError(error);
    return { success: false, error: errorMessage };
  }
};

export const claimByCode = (redemptionCode, userId) => async (dispatch, getState) => {
  try {
    const response = await axiosInstance.post("/Rewards/claim-by-code", {
      redemptionCode,
      userId
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    
    // Refresh winners list if we're in the rewards admin interface
    if (getState().rewards.winnersData.length > 0) {
      dispatch(getWinners(getState().rewards.winnersFilter));
    }
    
    return { success: true, data: response.data };
  } catch (error) {
    const errorMessage = handleError(error);
    return { success: false, error: errorMessage };
  }
};

export default rewardsSlice.reducer;
