import {
  AppBar,
  Avatar,
  Divider,
  Menu,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { prepareTranslation } from "../../plugins/helper";
import {
  AdminLayoutContainer,
  StyledMenuItem,
  tabsHeight,
  toolbarHeight,
} from "./style";
import logo from "../../assets/logo.png";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/features/authSlice";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { FlexRow, StyledTab } from "../../components/sharedComponents";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import LanguageSwitcherVertical from "../../components/LanguageSwitcherVertical/languageSwitcherMenu"; 

const AdminPanel = () => {
  const location = useLocation();
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);

  const mapLocationToTab = useCallback((location) => {
    if (location.includes("users")) return 0;
    else if (location.includes("usergroups")) return 1;
    else if (location.includes("social-networks")) return 2;
    else if (location.includes("general-settings")) return 3;
    else if (location.includes("rewards")) return 4;
    else return false;
  }, []);

  const theme = useTheme();
  const { loggedUserData } = useSelector((store) => store.core);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tabValue, setTabValue] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onTabChange = (event, newValue) => {
    setTabValue(newValue);
    switch (newValue) {
      case 0:
        navigate("users");
        break;
      case 1:
        navigate("usergroups");
        break;
      case 2:
        navigate("social-networks");
        break;
      case 3:
        navigate("general-settings");
        break;
      case 4:
        navigate("rewards");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setTabValue(mapLocationToTab(location.pathname));
  }, [location, mapLocationToTab]);

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AdminLayoutContainer>
      <AppBar position="static" sx={{ background: "#0a3f68", color: "white" }}>
        <Toolbar
          sx={{ borderBottom: "1px solid #c4d5e0", height: toolbarHeight }}
        >
          <Box
            component="img"
            alt="telecomlogo"
            src={logo}
            sx={{
              width: "180px",
              height: "45px",
              marginTop: "-5px",
            }}
          />

          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              fontStyle: "italic",
              fontWeight: "bold",
              marginLeft: "1em",
            }}
          >
            {t(prepareTranslation("Admin Panel"))}
          </Typography>

          <Avatar
            onClick={(e) => setAnchorEl(e.currentTarget)}
            sx={{
              backgroundColor: theme.palette.primary.main,
              width: "2.2em",
              height: "2.2em",
              marginRight: "0.7em",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#c70404e8",
              },
            }}
          />
        </Toolbar>
        <Tabs
          sx={{ minHeight: tabsHeight, maxHeight: tabsHeight }}
          value={tabValue}
          onChange={onTabChange}
          aria-label="basic tabs example"
          variant="fullWidth"
          TabIndicatorProps={{
            style: { color: "white" },
          }}
        >
          {["Users", "User Groups", "Social Networks", "General Settings", "Rewards"].map(
            (el) => (
              <StyledTab key={el} label={t(prepareTranslation(el))} />
            )
          )}
        </Tabs>
      </AppBar>
      <Box
        sx={{
          width: "100%",
          height: `calc(100vh - ${toolbarHeight} - ${tabsHeight})`,
          padding: "1em 2em",
          boxSizing: "border-box",
          overflow: "hidden",
        }}
      >
        <Outlet />
      </Box>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={anchorEl != null}
        onClose={handleUserMenuClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <FlexRow
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            height: "35px",
          }}
        >
          <Typography
            sx={{ paddingBottom: "10px", fontWeight: "bold", fontSize: "14px" }}
          >
            {loggedUserData?.userData?.userName}
          </Typography>
        </FlexRow>
        <Divider flexItem />
        <StyledMenuItem
          onClick={() => {
            navigate("user-management");
            handleUserMenuClose();
          }}
        >
          <AccountCircleOutlinedIcon sx={{ color: "gray" }} />
          {t(prepareTranslation("My account"))}
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => setLanguageMenuOpen(!languageMenuOpen)}
        >
          <LanguageIcon sx={{ color: "gray" }} />
          {t(prepareTranslation("Language"))}
          {languageMenuOpen && <LanguageSwitcherVertical />}
        </StyledMenuItem>
        <StyledMenuItem onClick={() => dispatch(logout())}>
          <LogoutOutlinedIcon sx={{ color: "gray" }} />
          {t(prepareTranslation("Logout"))}
        </StyledMenuItem>
      </Menu>
    </AdminLayoutContainer>
  );
};

export default AdminPanel;
