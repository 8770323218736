import React, { useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Typography,
  Paper,
  Tooltip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTranslation } from 'react-i18next';
import { prepareTranslation } from '../../plugins/helper';

const ImageUpload = ({ currentImage, onImageChange, onRemoveImage }) => {
  const { t } = useTranslation();
  const [previewUrl, setPreviewUrl] = useState(currentImage || '');

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
      onImageChange(file);
    }
  };

  const handleRemove = () => {
    setPreviewUrl('');
    onRemoveImage();
  };

  return (
    <Box sx={{ mb: 2 }}>
      <input
        accept="image/*"
        type="file"
        id="image-upload"
        style={{ display: 'none' }}
        onChange={handleImageChange}
      />
      {previewUrl ? (
        <Paper
          elevation={3}
          sx={{
            p: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            maxWidth: 300
          }}
        >
          <Box
            component="img"
            src={previewUrl}
            alt="Preview"
            sx={{
              width: 100,
              height: 100,
              objectFit: 'cover',
              borderRadius: 1
            }}
          />
          <Tooltip title={t(prepareTranslation("Remove image"))}>
            <IconButton
              onClick={handleRemove}
              size="small"
              sx={{ color: 'error.main' }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Paper>
      ) : (
        <label htmlFor="image-upload">
          <Button
            component="span"
            variant="outlined"
            startIcon={<CloudUploadIcon />}
            sx={{ mb: 1 }}
          >
            {t(prepareTranslation("Upload Image"))}
          </Button>
        </label>
      )}
      <Typography variant="caption" display="block" color="text.secondary">
        {t(prepareTranslation("Supported formats: JPG, PNG. Max size: 256x256"))}
      </Typography>
    </Box>
  );
};

export default ImageUpload;
