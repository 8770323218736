import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Divider,
  Avatar,
  TextField,
  CircularProgress,
  Link,
  Grid,
  Paper,
  IconButton
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { prepareTranslation } from '../../plugins/helper';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';

// Function to determine if text should be dark or light based on background color
const getContrastTextColor = (hexColor) => {
  // Default to dark text if no color provided
  if (!hexColor) return '#000';
  
  // Remove # if present
  const color = hexColor.replace('#', '');
  
  // Convert hex to RGB
  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);
  
  // Calculate luminance - standard formula for relative luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  
  // Return white for dark backgrounds, black for light backgrounds
  return luminance > 0.5 ? '#000' : '#fff';
};

const RewardModal = ({ open, onClose, reward, onClaim, isClaimable, isAnonymousUser, onRegisterEmail, onGenerateCode }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(true);
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [redemptionCode, setRedemptionCode] = useState('');
  const [codeCopied, setCodeCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [anonymousFlowStage, setAnonymousFlowStage] = useState('initial'); // 'initial', 'email', 'code'
  
  console.log("RewardModal props:", { open, reward, isClaimable, isAnonymousUser });
  
  useEffect(() => {
    if (open) {
      // Reset states when modal opens
      setEmail('');
      setEmailValid(true);
      setEmailSubmitted(false);
      setRedemptionCode('');
      setCodeCopied(false);
      setAnonymousFlowStage('initial');
    }
  }, [open]);
  
  if (!reward) return null;
  
  const { title, description, backgroundColor, imageUrl } = reward.reward;
  
  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(email);
  };
  
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setEmailValid(value === '' || validateEmail(value));
  };
  
  const handleEmailSubmit = async () => {
    if (email && !validateEmail(email)) {
      setEmailValid(false);
      return;
    }
    
    setIsLoading(true);
    try {
      if (email) {
        // If this completes without throwing an error, consider it successful
        // The server returns HTTP 200 without a response body that we can check
        await onRegisterEmail(email);
        console.log("Email registration completed successfully");
        
        // Proceed with code generation regardless of response content
        setEmailSubmitted(true);
        generateRedemptionCode();
      } else {
        // No email provided, skip to code generation
        setEmailSubmitted(true);
        generateRedemptionCode();
      }
    } catch (error) {
      // Only show an error if an exception is thrown
      console.error("Error submitting email:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const generateRedemptionCode = async () => {
    setIsLoading(true);
    try {
      const code = await onGenerateCode();
      console.log("Code received in modal:", code);
      if (code) {
        setRedemptionCode(code);
        setAnonymousFlowStage('code');
      } else {
        // If no code received, show error
        console.error("No redemption code received from backend");
      }
    } catch (error) {
      console.error("Error generating code:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const copyCodeToClipboard = () => {
    navigator.clipboard.writeText(redemptionCode)
      .then(() => {
        setCodeCopied(true);
        setTimeout(() => setCodeCopied(false), 2000);
      })
      .catch(err => {
        console.error('Could not copy text: ', err);
      });
  };
  
  const skipEmail = () => {
    // Skip email collection and go straight to code generation
    generateRedemptionCode();
  };
  
  // Different content for each stage of the anonymous flow
  const renderAnonymousContent = () => {
    switch (anonymousFlowStage) {
      case 'email':
        return (
          <Box sx={{ py: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              {t('enter_your_email')}
            </Typography>
            <Typography variant="body2" sx={{ mb: 3 }}>
              {t('we_will_send_you_a_reminder_with_your_redemption_code')}
            </Typography>
            
            <TextField
              fullWidth
              label={t('email_address')}
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
              error={!emailValid}
              helperText={!emailValid ? t('please_enter_a_valid_email_address') : ''}
              disabled={isLoading || emailSubmitted}
              sx={{ mb: 3 }}
            />
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button
                variant="text"
                onClick={skipEmail}
                disabled={isLoading || emailSubmitted}
              >
                {t('skip')}
              </Button>
              
              <Button
                variant="contained"
                color="primary"
                onClick={handleEmailSubmit}
                disabled={isLoading || emailSubmitted}
                startIcon={isLoading ? <CircularProgress size={20} /> : <EmailIcon />}
              >
                {t('continue')}
              </Button>
            </Box>
          </Box>
        );
        
      case 'code':
        return (
          <Box sx={{ pb: 3, pt: 0 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'center' }}>
              {t('your_redemption_code')}
            </Typography>
            
            <Paper
              elevation={3}
              sx={{
                p: 2,
                mb: 3,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: theme.palette.background.default,
                border: `1px solid ${theme.palette.divider}`
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: 'bold', letterSpacing: 1 }}>
                {redemptionCode}
              </Typography>
              <IconButton onClick={copyCodeToClipboard} color={codeCopied ? "success" : "default"}>
                <ContentCopyIcon />
              </IconButton>
            </Paper>
            
            {codeCopied && (
              <Typography variant="body2" color="success.main" sx={{ mb: 2, textAlign: 'center' }}>
                {t('code_copied_to_clipboard')}
              </Typography>
            )}
            
            <Typography variant="body2" sx={{ mb: 3 }}>
              {t('download_our_app_and_use_this_code_to_claim_your_reward_this_code_expires_in_15_days')}
            </Typography>
            
            <Typography variant="subtitle2" sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}>
              {t('get_the_app')}
            </Typography>
            
            <Grid container spacing={2} sx={{ mb: 2, justifyContent: 'center' }}>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  fullWidth
                  variant="outlined"
                  startIcon={<AppleIcon />}
                  component={Link}
                  href="https://apps.apple.com/hr/app/tagtec/id6737224689"
                  target="_blank"
                  sx={{ borderRadius: '8px', py: 1 }}
                >
                  {t(prepareTranslation('app_store'))}
                </Button>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  fullWidth
                  variant="outlined"
                  startIcon={<AndroidIcon />}
                  component={Link}
                  href="https://play.google.com/store/apps/details?id=com.tags.tagtec&hl=en"
                  target="_blank"
                  sx={{ borderRadius: '8px', py: 1 }}
                >
                  {t(prepareTranslation('play_store'))}
                </Button>
              </Grid>
            </Grid>
          </Box>
        );
        
      default: // initial stage
        return (
          <>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
              {title}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {description}
            </Typography>
            
            <Divider sx={{ my: 2 }} />
            
            <Typography variant="body2" color="text.secondary">
              {t('to_claim_this_reward_download_our_app_and_create_an_account')}
            </Typography>
          </>
        );
    }
  };
  
  return (
    <Dialog
      open={open}
      onClose={anonymousFlowStage === 'code' ? onClose : null}
      maxWidth={anonymousFlowStage === 'code' ? "sm" : "xs"}
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '12px',
          overflow: 'hidden'
        }
      }}
    >
      {/* Close button in top-right corner, only for the code stage */}
      {anonymousFlowStage === 'code' && (
        <IconButton
          sx={{ position: 'absolute', top: 8, right: 8, color: 'white', zIndex: 1 }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
      
      <Box sx={{
        backgroundColor: backgroundColor || theme.palette.primary.main,
        pt: 2,
        pb: 3,
        px: 2,
        textAlign: 'center'
      }}>
        <DialogTitle sx={{
          color: getContrastTextColor(backgroundColor || theme.palette.primary.main),
          fontWeight: 'bold',
          fontSize: '1.5rem',
          pb: 0
        }}>
          {t('congratulations')}
        </DialogTitle>
        <DialogContent sx={{ color: getContrastTextColor(backgroundColor || theme.palette.primary.main), pt: 1 }}>
          <Typography variant="subtitle1">
            {t('you_ve_won_a_reward')}
          </Typography>
          {imageUrl && (
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
              <Avatar
                src={imageUrl ? `https://tagtec.ch/Resources/Images/${imageUrl}` : ''}
                alt={title}
                sx={{
                  width: 200,
                  height: 200,
                  border: `3px solid ${getContrastTextColor(backgroundColor || theme.palette.primary.main)}`
                }}
              />
            </Box>
          )}
        </DialogContent>
      </Box>
      
      <DialogContent sx={{ py: 3 }}>
        {isAnonymousUser ? renderAnonymousContent() : (
          <>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
              {title}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {description}
            </Typography>
            
            <Divider sx={{ my: 2 }} />
            
            <Typography variant="body2" color="text.secondary">
              {t('this_reward_can_be_claimed_at_the_business_location')}
            </Typography>
          </>
        )}
      </DialogContent>
      
      <DialogActions sx={{ px: 3, pb: 3, justifyContent: 'center' }}>
        {isClaimable ? (
          isAnonymousUser && anonymousFlowStage === 'initial' ? (
            <Button
              onClick={() => setAnonymousFlowStage('email')}
              variant="contained"
              color="primary"
              startIcon={<DownloadIcon />}
              fullWidth
              sx={{ borderRadius: '8px', py: 1 }}
            >
              {t('get_app_claim')}
            </Button>
          ) : !isAnonymousUser ? (
            <Button
              onClick={onClaim}
              variant="contained"
              color="primary"
              startIcon={<CardGiftcardIcon />}
              fullWidth
              sx={{ borderRadius: '8px', py: 1 }}
            >
              {t('claim_reward')}
            </Button>
          ) : null
        ) : (
          <Button
            onClick={onClose}
            variant="outlined"
            fullWidth
            sx={{ borderRadius: '8px', py: 1 }}
          >
            {t('close')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default RewardModal;