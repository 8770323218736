import React from 'react';
import { Box, Tooltip, Chip, Typography } from '@mui/material';
import { prepareTranslation } from '../../../plugins/helper';
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import KeyIcon from "@mui/icons-material/Key";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const winnerColumns = (t, additionalData) => {
  const usersData = additionalData?.usersData || [];
  const rewardsData = additionalData?.rewardsData || [];

  return [
    {
      id: 'reward',
      field: 'rewardId',
      label: 'reward',
      flex: 2,
      render: (value, row) => {
        // Try to find the full reward data using rewardId
        const rewardData = rewardsData.find(r => r.id === row.rewardId);
        
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {rewardData?.imageUrl ? (
              <Box
                component="img"
                src={`https://tagtec.ch/Resources/Images/${rewardData.imageUrl}`}
                alt=""
                sx={{
                  width: 32,
                  height: 32,
                  borderRadius: '4px',
                  backgroundColor: rewardData.backgroundColor || '#f0f0f0',
                  objectFit: 'cover'
                }}
              />
            ) : (
              <CardGiftcardOutlinedIcon sx={{ width: 32, height: 32, color: '#831c1a' }} />
            )}
            <Box>
              <Box sx={{ fontWeight: 'bold' }}>
                {rewardData?.title || `Reward #${row.rewardId}`}
              </Box>
              <Box sx={{ fontSize: '0.8rem', color: 'text.secondary' }}>
                {row.description || rewardData?.description || '-'}
              </Box>
            </Box>
          </Box>
        );
      }
    },
    {
      id: 'userId',
      field: 'userId',
      label: 'winner',
      flex: 1,
      render: (value, row) => {
        const user = usersData?.find(u => u.id === row.userId);
        const isAnonymous = row.isAnonymousWin;
        
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {isAnonymous ? (
              <PersonOffOutlinedIcon fontSize="small" color="action" />
            ) : (
              <PersonOutlineIcon fontSize="small" color="primary" />
            )}
            <Tooltip title={`${user?.userName || row.userId} (${row.deviceId})`}>
              <Typography variant="body2">
                {isAnonymous
                  ? t(prepareTranslation('anonymous_user'))
                  : (user?.userName || row.userId)}
              </Typography>
            </Tooltip>
          </Box>
        );
      }
    },
    {
      id: 'wonAt',
      field: 'wonAt',
      label: 'won_at',
      flex: 1,
      render: (value, row) => {
        const date = new Date(row.wonAt);
        return date.toLocaleString();
      }
    },
    {
      id: 'isClaimed',
      field: 'isClaimed',
      label: 'status',
      flex: 0.8,
      render: (value, row) => (
        <Chip
          label={row.isClaimed ? t(prepareTranslation('claimed')) : t(prepareTranslation('unclaimed'))}
          color={row.isClaimed ? 'success' : 'warning'}
          size="small"
        />
      )
    },
    {
      id: 'redemptionCode',
      field: 'redemptionCode',
      label: 'redemption_code',
      flex: 1,
      render: (value, row) => {
        if (!row.isAnonymousWin || !row.redemptionCode) {
          return '-';
        }
        
        const isExpired = row.redemptionCodeExpiresAt && new Date(row.redemptionCodeExpiresAt) < new Date();
        
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <KeyIcon fontSize="small" color={isExpired ? "error" : "info"} />
            <Tooltip
              title={
                isExpired
                  ? t(prepareTranslation('expired_on')) + ' ' + new Date(row.redemptionCodeExpiresAt).toLocaleString()
                  : t(prepareTranslation('expires_on')) + ' ' + new Date(row.redemptionCodeExpiresAt).toLocaleString()
              }
            >
              <Typography
                variant="body2"
                sx={{
                  fontFamily: 'monospace',
                  color: isExpired ? 'error.main' : 'inherit',
                  textDecoration: isExpired ? 'line-through' : 'none'
                }}
              >
                {row.redemptionCode}
              </Typography>
            </Tooltip>
          </Box>
        );
      }
    },
    {
      id: 'pendingEmail',
      field: 'pendingEmail',
      label: 'pending_email',
      flex: 1.2,
      render: (value, row) => {
        if (!row.isAnonymousWin || !row.pendingEmail) {
          return '-';
        }
        
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <MailOutlineIcon fontSize="small" color="info" />
            <Tooltip title={row.pendingEmail}>
              <Typography variant="body2" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {row.pendingEmail}
              </Typography>
            </Tooltip>
          </Box>
        );
      }
    }
  ];
};

export default winnerColumns;
