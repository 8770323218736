import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/authSlice";
import coreReducer from "./features/coreSlice";
import profilesReducer from "./features/profilesSlice";
import usersReducer from "./features/usersSlice";
import groupsReducer from "./features/groupsSlice";
import socialNetworksReducer from "./features/socialNetworksSlice";
import rewardsReducer from "./features/rewardsSlice";

const store = configureStore({
  reducer: {
    core: coreReducer,
    auth: authReducer,
    profiles: profilesReducer,
    users: usersReducer,
    groups: groupsReducer,
    socialNetworks: socialNetworksReducer,
    rewards: rewardsReducer,
  },
});

export default store;
