/**
 * Utility functions for device ID management
 */

/**
 * Generates a UUID v4
 * @returns {string} A random UUID
 */
export function generateUUID() {
  // Simple UUID v4 implementation
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0, 
          v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

/**
 * Gets the device ID from localStorage or creates a new one if it doesn't exist
 * @returns {string} The device ID
 */
export function getOrCreateDeviceId() {
  let deviceId = localStorage.getItem('taglife_device_id');
  if (!deviceId) {
    deviceId = generateUUID();
    localStorage.setItem('taglife_device_id', deviceId);
  }
  return deviceId;
}