import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import AdminPanel from "../pages/AdminPanel";
import AdminGeneralSettings from "../pages/AdminPanel/AdminGeneralSettings";
import AdminSocialNetworks from "../pages/AdminPanel/AdminSocialNetworks";
import AdminUserGroups from "../pages/AdminPanel/AdminUserGroups";
import AdminUsers from "../pages/AdminPanel/AdminUsers";
import AdminRewards from "../pages/AdminPanel/AdminRewards";
import UserProfile from "../pages/AdminPanel/AdminUsers/UserProfile";
import UserSocialNetworks from "../pages/AdminPanel/AdminUsers/UserSocialNetworks";
import UserManagement from "../pages/UserManagement";
import Connection from "../pages/Connection";
import Connections from "../pages/Connections";
import HelpCenter from "../pages/HelpCenter";
import Home from "../pages/Home";
import Login from "../pages/Login";
import ModeratorPanel from "../pages/ModeratorPanel";
import ModeratorUsers from "../pages/ModeratorPanel/ModeratorUsers";
import NotFound from "../pages/NotFound";
import Profile from "../pages/Profile";
import Registration from "../pages/Registration";
import ResetPassword from "../pages/ResetPages/ResetPassword";
import SocialNetworks from "../pages/SocialNetworks";
import Activation from "../pages/TransitionPages/Activation";
import CheckMail from "../pages/TransitionPages/CheckMail";
import PasswordResetSuccess from "../pages/TransitionPages/PasswordResetSuccess";
import {
  ADMIN_ROLE,
  checkUserRole,
  isLoggedIn,
  MODERATOR_ROLE,
} from "../plugins/helper";
import PrivateRoute from "./PrivateRoute";
import TagPage from "../pages/TagPage";
import AccountDeletion from "../pages/AccountDeletion";

export const calculateRedirectRoute = (role) => {
  return checkUserRole(role, ADMIN_ROLE)
    ? "/adminpanel"
    : checkUserRole(role, MODERATOR_ROLE)
    ? "/moderatorpanel"
    : "/home";
};

const RoutesList = () => {
  const { me } = useSelector((store) => store.auth);
  const { appSettings } = useSelector((store) => store.core);

  return appSettings == null ? null : (
    <Routes>
      <Route
        path="/"
        element={
          <Navigate
            to={!isLoggedIn() ? "/login" : calculateRedirectRoute(me?.role)}
            replace
          />
        }
      />
      <Route
        path="/login"
        element={
          <PrivateRoute nonLoggedIn>
            <Login />
          </PrivateRoute>
        }
      />
      <Route
        path="/home"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      {!me?.hasProfile && (
        <>
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />

          <Route
            path="/profile/social-networks"
            element={
              <PrivateRoute>
                <SocialNetworks />
              </PrivateRoute>
            }
          />
          <Route
            path="/connections"
            element={
              <PrivateRoute>
                <Connections />
              </PrivateRoute>
            }
          />

          <Route
            path="/connections/:username"
            element={
              <PrivateRoute>
                <Connection />
              </PrivateRoute>
            }
          />
        </>
      )}

      <Route path="/tag/:userId" element={<TagPage />} />
      <Route path="/account-deletion" element={<AccountDeletion />} />

      <Route
        path="/user-management"
        element={
          <PrivateRoute>
            <UserManagement />
          </PrivateRoute>
        }
      />

      <Route
        path="/help-center"
        element={
          <PrivateRoute>
            <HelpCenter />
          </PrivateRoute>
        }
      />
      <Route path="/activation" element={<Activation />} />
      {appSettings?.canRegister && (
        <Route
          path="/registration"
          element={
            <PrivateRoute nonLoggedIn>
              <Registration />
            </PrivateRoute>
          }
        />
      )}
      <Route
        path="/checkmail"
        element={
          <PrivateRoute nonLoggedIn>
            <CheckMail />
          </PrivateRoute>
        }
      />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/reset-successful" element={<PasswordResetSuccess />} />

      <Route
        path="/adminpanel"
        element={
          <PrivateRoute roleRoute={ADMIN_ROLE}>
            <AdminPanel />
          </PrivateRoute>
        }
      >
        <Route path="" element={<Navigate to="users" replace />} />
        <Route path="users" element={<AdminUsers />} />
        <Route path="users/profile/:profileId" element={<UserProfile />} />
        <Route
          path="users/social-networks/:profileId"
          element={<UserSocialNetworks />}
        />
        <Route path="usergroups" element={<AdminUserGroups />} />
        <Route path="general-settings" element={<AdminGeneralSettings />} />
        <Route path="social-networks" element={<AdminSocialNetworks />} />
        <Route path="rewards" element={<AdminRewards />} />
        <Route path="user-management" element={<UserManagement />} />
      </Route>
      <Route
        path="/moderatorpanel"
        element={
          <PrivateRoute roleRoute={MODERATOR_ROLE}>
            <ModeratorPanel />
          </PrivateRoute>
        }
      >
        <Route path="" element={<Navigate to="users" replace />} />
        <Route path="users" element={<ModeratorUsers />} />
        <Route path="user-management" element={<UserManagement />} />
      </Route>
      <Route path="/not-found" element={<NotFound />} />
      <Route path="*" element={<Navigate to="not-found" replace />} />
    </Routes>
  );
};

export default RoutesList;
